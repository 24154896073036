import { FC } from "react";

import styles from "./Alert.module.scss";

import warningIcon from "@/app/assets/icons/warning_icon.svg";
import qrcodeIcon from "@/app/assets/icons/qr_code.svg";
import pausedIcon from "@/app/assets/icons/speakerIcon.svg";
import checkmarkIcon from "@/app/assets/icons/checkmark_light.svg";
import Image from 'next/image';
import classNames from "classnames";
import ButtonElement from "@/shared/ButtonElement";


interface AlertProps {
  className?: string;
  title?: string;
  message: string;
  type: "info" | "error" | "warning" | "scanner" | "paused" | "verified" | "default";
  icon?: string;
  buttonText?: string;
  onClick?: () => void;
}

const Alert: FC<AlertProps> = (
  {
    className = "",
    title,
    message,
    type,
    icon,
    buttonText,
    onClick
  }) => {
  return (
    <div className={classNames(styles.root, styles[type], className)}>
      <div className={styles.root__info}>
        {icon && <Image className={styles.info__icon} src={icon} alt="icon" />}
        {!icon && (
          <>
            {type === "warning" && <Image className={styles.info__icon} src={warningIcon} alt="warning" />}
            {type === "scanner" && <Image className={styles.info__icon} src={qrcodeIcon} alt="scanner" />}
            {type === "paused" && <Image className={styles.info__icon} src={pausedIcon} alt="paused" />}
            {type === "verified" && <Image className={styles.info__icon} src={checkmarkIcon} alt="verified" />}
          </>
        )}

        <div className={styles.root__info__details}>
          {title && <div className={styles.root__info__details__title}>{title}</div>}
          <div className={styles.root__info__details__message}>{message}</div>
        </div>
      </div>
      {
        !!buttonText && !!onClick && (<ButtonElement
          className={styles.button}
          text={buttonText}
          color={"secondary"}
          size={"small"}
          onClick={onClick}
        />)
      }
    </div>
  );
};

export default Alert;
