'use client';
import React, {FC, useState} from 'react';
import styles from './ButtonElement.module.scss';
import Loader from "../../app/assets/icons/components/Loader";
import Link from "next/link";

export interface ButtonElementProps {
  className?: string;
  href?: string;
  color?: 'dark' | 'transparent' | 'link' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  children?: React.ReactNode;
  type?: 'button' | 'submit';
  disabled?: boolean;
  onClick?: () => Promise<any> | void;
  blank?: boolean;
  prefetch?: boolean;
  text: string;
}

const ButtonElement: FC<ButtonElementProps> = (
  {
    className = '',
    href,
    color = 'default',
    size = 'medium',
    children,
    disabled = false,
    type = 'button',
    text,
    blank = false,
    prefetch = false,
    onClick,
  }) => {
  const buttonClasses = `${styles.root} ${styles[color]} ${styles[size]} ${className}`;
  const [promiseLoading, setPromiseLoading] = useState(false);

  const handleButtonClick = async () => {
    if (onClick && !promiseLoading) {
      setPromiseLoading(true);
      try {
        await onClick();
      } finally {
        setPromiseLoading(false);
      }
    }
  }

  if (href) {
    return (
      <Link
        href={href}
        target={blank ? '_blank' : '_self'}
        className={buttonClasses}>
        {children || text}
      </Link>
    );
  } else {
    return (
      <button
        aria-label={text}
        name={text}
        onClick={handleButtonClick}
        disabled={disabled || promiseLoading}
        type={type}
        className={buttonClasses}>
        {promiseLoading && <Loader/>}
        {children || text}
      </button>
    );
  }
};

export default ButtonElement;
