import {FC} from "react";

const Loader: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        animation: 'rotate .5s ease-in-out infinite'
      }}
    >
      <style>
        {`@keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }`}
      </style>
        <mask id="path-1-inside-1_6590_17457" fill="white">
          <path d="M10 1C10 0.447715 10.4488 -0.00518167 10.9984 0.0499541C12.6497 0.21564 14.2395 0.790455 15.6208 1.72919C17.2788 2.85593 18.5598 4.45495 19.2978 6.31875C20.0357 8.18256 20.1965 10.2251 19.7592 12.1814C19.3219 14.1377 18.3067 15.9175 16.8455 17.2897C15.3842 18.6619 13.5442 19.5633 11.5643 19.8769C9.58445 20.1905 7.556 19.9018 5.74221 19.0483C3.92841 18.1948 2.41299 16.8158 1.39258 15.0904C0.542426 13.6529 0.0685696 12.0301 0.0069002 10.3716C-0.0136218 9.81973 0.466565 9.40021 1.01776 9.43488V9.43488C1.56895 9.46956 1.98114 9.94583 2.01545 10.4971C2.09371 11.7542 2.46811 12.9801 3.11406 14.0723C3.93039 15.4527 5.14273 16.5558 6.59377 17.2386C8.0448 17.9214 9.66756 18.1524 11.2515 17.9015C12.8354 17.6506 14.3074 16.9295 15.4764 15.8317C16.6454 14.734 17.4575 13.3102 17.8073 11.7451C18.1572 10.1801 18.0286 8.54605 17.4382 7.055C16.8479 5.56396 15.823 4.28475 14.4967 3.38336C13.4471 2.6701 12.2472 2.21945 10.9974 2.06242C10.4495 1.99356 10 1.55228 10 1V1Z"/>
        </mask>
        <path d="M10 1C10 0.447715 10.4488 -0.00518167 10.9984 0.0499541C12.6497 0.21564 14.2395 0.790455 15.6208 1.72919C17.2788 2.85593 18.5598 4.45495 19.2978 6.31875C20.0357 8.18256 20.1965 10.2251 19.7592 12.1814C19.3219 14.1377 18.3067 15.9175 16.8455 17.2897C15.3842 18.6619 13.5442 19.5633 11.5643 19.8769C9.58445 20.1905 7.556 19.9018 5.74221 19.0483C3.92841 18.1948 2.41299 16.8158 1.39258 15.0904C0.542426 13.6529 0.0685696 12.0301 0.0069002 10.3716C-0.0136218 9.81973 0.466565 9.40021 1.01776 9.43488V9.43488C1.56895 9.46956 1.98114 9.94583 2.01545 10.4971C2.09371 11.7542 2.46811 12.9801 3.11406 14.0723C3.93039 15.4527 5.14273 16.5558 6.59377 17.2386C8.0448 17.9214 9.66756 18.1524 11.2515 17.9015C12.8354 17.6506 14.3074 16.9295 15.4764 15.8317C16.6454 14.734 17.4575 13.3102 17.8073 11.7451C18.1572 10.1801 18.0286 8.54605 17.4382 7.055C16.8479 5.56396 15.823 4.28475 14.4967 3.38336C13.4471 2.6701 12.2472 2.21945 10.9974 2.06242C10.4495 1.99356 10 1.55228 10 1V1Z" stroke="white" strokeWidth="2" mask="url(#path-1-inside-1_6590_17457)"/>
      </svg>


      )
}

export default Loader;
