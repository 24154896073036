'use client';

import {FC, useEffect, useState} from 'react';
import classNames from 'classnames';
import {useParams, useRouter} from 'next/navigation';
import Image from 'next/image';
import Link from 'next/link';
import {useCookies} from 'react-cookie';
import {useTranslations} from 'next-intl';
import OtpInput from 'react-otp-input';

import styles from './AuthPopup.module.scss';
import PopupContainer from '../PopupContainer';
import InputComponent from '../InputComponent';
import ButtonElement from '../ButtonElement';

import PersonIcon from '../../app/assets/icons/person.svg';
import LetterIcon from '../../app/assets/icons/letter.svg';

import {AuthData} from '@/models/User.model';
import {useAppDispatch} from '@/app/redux/hooks';
import {sendCode, sendEmail} from '@/app/features/MainPage/mainSlice/MainActions';
import {mainPath} from '@/app/routes/MainPath';
import {validateEmail} from '@/helpers/functions';
import {useLocalStorage} from "usehooks-ts";

interface AuthPopupProps {
  className?: string;
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  isPopup?: boolean;
  onAuth?: () => Promise<void> | void;
}

const AuthPopup: FC<AuthPopupProps> = (
  {
    className = '',
    setIsOpen,
    isOpen,
    isPopup,
    onAuth,
  }) => {
  const t = useTranslations('AUTH_POPUP');

  const dispatch = useAppDispatch();

  const [step, setStep] = useState<number>(1);
  const [formData, setFormData] = useState<AuthData>({
    email: '',
    code: '',
  });
  const [codeError, setCodeError] = useState<boolean>(false);
  const [time, setTime] = useState<number>(120);

  const [token, setToken] = useLocalStorage('token', '');
  const [email, setEmail] = useLocalStorage('email', '');
  const [full_name, setFullName] = useLocalStorage('full_name', '');
  const [authDate, setAuthDate] = useLocalStorage<number>('auth_date', 0);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    if (step === 2) {
      setStep(1);
    }
    setFormData((prevData) => ({...prevData, email}));
  };

  const handleCodeChange = (code: string) => {
    setCodeError(false);
    setFormData((prevData) => ({...prevData, code}));
  };

  const isEmailValid = validateEmail(formData.email);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  const resetForm = () => {
    setStep(1);
    setFormData({email: '', code: ''});
    setCodeError(false);
    setTime(120);
  };

  const handleClose = () => {
    setIsOpen(false);
    resetForm();
  };

  const handleSubmit = async () => {
    try {
      if (step === 1) {
        await handleSendEmail();
      } else if (step === 2) {
        if (formData.code.length < 6 && time <= 0) {
          await handleSendEmail();
        } else if (formData.code.length === 6) {
          await handleVerifyCode();
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleSendEmail = async () => {
    const response = await dispatch(sendEmail(formData.email));
    if (response.meta.requestStatus === 'fulfilled') {
      setStep(2);
      setTime(120);
    }
  };

  const handleVerifyCode = async () => {
    const response = await dispatch(sendCode(formData));
    if (response.meta.requestStatus === 'fulfilled') {
      const payload = response.payload as { key: string, user: { email: string, full_name: string } };
      setToken(payload.key);
      setEmail(payload.user.email);
      setFullName(payload.user.full_name);
      setAuthDate(new Date().getTime());

      if (onAuth) {
        await onAuth();
      }

      if (isOpen) {
        handleClose();
      }
    } else {
      setCodeError(true);
    }
  };

  // Handle Enter key press in OTP input
  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <PopupContainer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      close={handleClose}
    >
      <form className={classNames(styles.root, className)}>
        {step === 1 && (
          <>
            <div className={styles.root__user}>
              <Image width={480} height={48} src={PersonIcon} alt="person"/>
            </div>

            <h2 className={styles.root__title}>{t('login')}</h2>
            <p className={styles.root__subtitle}>{t('title')}</p>

            <div className={styles.root__form}>
              <InputComponent
                error={formData.email.length > 6 && !isEmailValid}
                type="email"
                autoComplete="email"
                className={`${styles.input} mt24`}
                label={t('email')}
                placeholder="example@mail.com"
                onChange={handleEmailChange}
              />

              <ButtonElement
                onClick={handleSubmit}
                type="button"
                text="Confirm"
                disabled={formData.email.length < 6}
              >
                {t('send')}
              </ButtonElement>
            </div>
          </>
        )}

        {step === 2 && (
          <>
            <div className={styles.root__user}>
              <Image width={480} height={48} src={LetterIcon} alt="Letter"/>
            </div>

            <h2 className={styles.root__title}>{t('check')}</h2>
            <p className={styles.root__subtitle}>{t('subtitle')}</p>

            <div className={styles.root__otp}>
              <OtpInput
                value={formData.code}
                onChange={handleCodeChange}
                placeholder="●●●●●●"
                inputStyle={classNames({
                  [styles['root__otp--error']]: codeError,
                })}
                numInputs={6}
                renderInput={(props) => <input {...props} onKeyUp={onKeyUp}/>}
              />

              <ButtonElement
                onClick={handleSubmit}
                type="button"
                text="Confirm"
                disabled={time > 0 && formData.code.length !== 6}
              >
                {formData.code.length < 6 && time <= 0 && t('resend')}
                {formData.code.length < 6 && time > 0 && formatTime(time)}
                {formData.code.length === 6 && t('confirm')}
              </ButtonElement>
            </div>
          </>
        )}

        <p className={styles.root__notion}>
          {t('by')}
          <Link href={"/public-offer"} target="_blank">
            {t('public_offer')}
          </Link>
          {t('and')}
          <Link href={"/privacy-policy"} target="_blank">
            {t('privacy_policy')}
          </Link>
        </p>
      </form>
    </PopupContainer>
  );
};

export default AuthPopup;
