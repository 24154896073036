import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmailAuth, VerifyCodeBody, VerifyCodeAnswer } from '@/models/Auth.model';
import { cookies } from 'next/headers';

export const sendEmail = createAsyncThunk(
  '/auth/email-authorization/',
  async (email: string, thunkAPI) => {
    try {
      const response = await axios.post<EmailAuth>(
        `${process.env.NEXT_PUBLIC__APP_API_URL}auth/email-authorization/`,
        {
          email: email,
        },
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('The request is failure');
    }
  },
);

export const sendCode = createAsyncThunk(
  '/auth/verify-code/',
  async (body: VerifyCodeBody, thunkAPI) => {
    try {
      const response = await axios.post<VerifyCodeAnswer>(
        `${process.env.NEXT_PUBLIC__APP_API_URL}auth/verify-code/`,
        {
          ...body,
        },
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('The request is failure');
    }
  },
);
