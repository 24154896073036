'use client';
import {FC, useRef, ReactNode, Fragment} from 'react';
import styles from './PopupContainer.module.scss';
import {useOnClickOutside} from '@/helpers/hooks';
import close_icon from '../../app/assets/icons/close_2.svg';
import Image from 'next/image';
import {Dialog, Transition} from '@headlessui/react';

interface PopupContainerProps {
  className?: string;
  title?: string;
  subtitle?: string | null;
  close: () => void;
  children: ReactNode;
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
}

const PopupContainer: FC<PopupContainerProps> =
    ({
       className = '',
       title = null,
       subtitle = null,
       close,
       children,
       isOpen = false,
       setIsOpen,
     }) => {
      const ref = useRef<HTMLDivElement>(null);
      useOnClickOutside(ref, () => close());

      return (
          <Transition
              appear={true}
              show={isOpen}>
            <Dialog as="div" onClose={close}>
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
              >
                <div className={`${styles.backdrop}`}/>
              </Transition.Child>
              <div className="fixed inset-0 overflow-y-auto full-width z-30">
                <div className="flex min-h-full items-center justify-center p-4 text-center full-width">
                  <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className={"full-width"}>
                      <div ref={ref} className={styles.popup__block}>
                        <div className={styles.popup__bg}/>
                        <button onClick={() => close()} className={styles.popup__close} aria-label="close">
                          <Image src={close_icon} alt="close" width={24} height={24}/>
                        </button>
                        <h2 className={styles.popup__title}>{title}</h2>
                        {subtitle && <p className={styles.popup__subtitle}>{subtitle}</p>}
                        {children}
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
      );
    };

export default PopupContainer;
