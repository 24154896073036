import {createContext, useContext, useState, ReactNode, Dispatch, SetStateAction, FC} from "react";

interface BackgroundImageContextType {
  imageUrl: string;
  setImageUrl: Dispatch<SetStateAction<string>>;
}

const defaultImage = "";

const BackgroundImageContext = createContext<BackgroundImageContextType | undefined>(undefined);

export const useBackgroundImage = () => {
  const context = useContext(BackgroundImageContext);
  if (!context) {
    throw new Error('useBackgroundImage must be used within a BackgroundImageProvider');
  }
  return context;
};

interface BackgroundImageProviderProps {
  children: ReactNode;
}

export const BackgroundImageProvider: FC<BackgroundImageProviderProps> = ({ children }) => {
  const [imageUrl, setImageUrl] = useState(defaultImage);

  return (
      <BackgroundImageContext.Provider value={{ imageUrl, setImageUrl }}>
        {children}
      </BackgroundImageContext.Provider>
  );
};
