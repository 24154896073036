import React, { FC, ChangeEvent } from 'react';
import styles from './InputComponent.module.scss';

interface InputComponentProps {
  className?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;

  name?: string;
  type?: 'text' | 'date' | 'number' | 'email' | 'date' | 'datetime-local';
  value?: string | number;
  buttonTitle?: string | null;
  buttonAction?: () => void;
  buttonDisabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  error?: boolean;
}

const InputComponent: FC<InputComponentProps> = ({
  className = '',
  autoComplete = '',
  type = 'text',
  name = '',
  placeholder = 'Enter some value',
  disabled = false,
  buttonTitle = null,
  buttonDisabled = false,
  buttonAction,
  label,
  value,
  onChange,
  error = false,
}) => {
  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.header}>
        {label && (
          <label className={styles.label} htmlFor="">
            {label}
          </label>
        )}
        {buttonTitle && (
          <button disabled={buttonDisabled} className={styles.button} onClick={buttonAction}>
            {buttonTitle}
          </button>
        )}
      </div>

      <input
        autoComplete={autoComplete}
        className={`${styles.input} ${error && styles.input__error}`}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        type={type}
        value={value}
      />

      {error && (
        <p className={styles.error}>
          Oh something went wrong, check the data entered in the field.
        </p>
      )}
    </div>
  );
};

export default InputComponent;
